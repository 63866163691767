<template>
    <div v-if="!loaderr" class="page-layout">
        <van-nav-bar
            v-if="showHeader"
            :left-arrow="leftArrow"
            v-bind="$attrs"
            v-on="$listeners"
            @click-left="onClickLeft">
                <slot name="header-icon" slot="right"></slot>
        </van-nav-bar>

        <div class="page-layout__content"
            v-bind:style="{background: '#' + backgroundColor}">
            <slot></slot>
        </div>

    </div>
    <err-layout v-on="$listeners" v-else @reload="onReload"/>
</template>

<script>
import { NavBar } from 'vant'
import ErrLayout from './ErrLayout'

export default {
    components: {
        ErrLayout,
        [NavBar.name]: NavBar,
    },
    props: {
        showHeader: {
            type: Boolean,
            default: false
        },
        leftArrow: {
            type: Boolean,
            default: true
        },
        backgroundColor: {
            type: String,
            default: ''
        },
        loaderr: {
            type: Boolean,
            default: false
        }
    },
    inheritAttrs: false,
    methods: {
        onClickLeft() {
            this.$router.back()
        },
        onReload() {
            this.$emit('reload')
        }
    }
}
</script>

<style scoped>
.page-layout {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.page-layout__content {
    flex-grow: 2;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}


</style>