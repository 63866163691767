<template>
	<div>
		<div class="filter-mask" @click="$emit('close')"></div>
		<div class="filter-content">
			<div class="filter-content__con">
				<div class="filter-title">
					筛选类目
				</div>
				<div class="filter-cardbox">
					部门
					<div class="filter-cardbox__inner">
						<span v-for="(item, idx) in deptList" :key="idx" v-bind:class="[item.select ? 'filter-cardbox__selected' : '']" @click="onCardSelect('dept', idx)">{{ item.name }}</span>
					</div>
				</div>
				<div class="filter-cardbox">
					分类
					<div class="filter-cardbox__inner">
						<span v-for="(item, idx) in tagList" :key="idx" v-bind:class="[item.select ? 'filter-cardbox__selected' : '']" @click="onCardSelect('tag', idx)">{{ item.name }}</span>
					</div>
				</div>

				<div class="filter-cardbox">
					项目类型
					<div class="filter-cardbox__inner">
						<span v-for="(item, idx) in projectList" :key="idx" v-bind:class="[item.select ? 'filter-cardbox__selected' : '']" @click="onCardSelect('proj', idx)">{{ item.name }}</span>
					</div>
				</div>

				<div class="filter-cardbox">
					专题
					<div class="filter-cardbox__inner">
						<span v-for="(item, idx) in subjectList" :key="idx" v-bind:class="[item.select ? 'filter-cardbox__selected' : '']" @click="onCardSelect('subj', idx)">{{ item.name }}</span>
					</div>
				</div>

				<!-- <div class="filter-cardbox">
                    时间范围
                    <div class="filter-cardbox__time">
                        <van-field
                                clickable
                                :value="startTime"
                                placeholder="开始时间"
                                @click="onStartimePicker"/>
                        <span>——</span>
                        <van-field
                                clickable
                                :value="endTime"
                                placeholder="结束时间"
                                @click="onStartimePicker"/>
                    </div>
                </div> -->
			</div>

			<div class="filter-content__btn-box">
				<div @click="onRest" class="filter-content__btn-reset">重置</div>
				<div @click="onConfirm" class="filter-content__btn-confirm">确定</div>
			</div>
		</div>

		<van-popup v-model="showPicker" position="bottom">
			<van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate" :formatter="formatter" @cancel="showPicker = false" @confirm="changeTime" />
		</van-popup>
	</div>
</template>

<script>
import { DatetimePicker, Field, Popup } from "vant";
import { getPolicyTag } from "@/api/policy";
import to from "@/utils/to";
export default {
	components: {
		[DatetimePicker.name]: DatetimePicker,
		[Field.name]: Field,
		[Popup.name]: Popup,
	},
	data() {
		return {
			curTimeType: "beg",
			showPicker: false,
			startTime: "",
			endTime: "",

			minDate: new Date(1990, 10, 1),
			maxDate: new Date(),
			currentDate: new Date(),
			tagList: [],
			deptList: [
				{
					name: "人社",
					select: false,
				},
				{
					name: "医保",
					select: false,
				},
			],
			projectList: [
				{
					name: "抵扣",
					select: false,
				},
				{
					name: "减免",
					select: false,
				},
				{
					name: "资金",
					select: false,
				},
			],
			subjectList: [
				{
					name: "政策",
					select: false,
				},
				{
					name: "解读",
					select: false,
				},
				{
					name: "公告",
					select: false,
				},
			],
		};
	},
	created() {
		this.getPolicyTag();
	},
	methods: {
		onCardSelect(type, idx) {
			if (type === "tag") {
				const status = this.tagList[idx].select;
				this.$set(this.tagList[idx], "select", !status);
			} else if (type === "dept") {
				const status = this.deptList[idx].select;
				this.$set(this.deptList[idx], "select", !status);
			} else if (type === "proj") {
				const status = this.projectList[idx].select;
				this.$set(this.projectList[idx], "select", !status);
			} else if (type === "subj") {
				const status = this.subjectList[idx].select;
				this.$set(this.subjectList[idx], "select", !status);
			}
		},
		onStartimePicker() {
			this.curTimeType = "beg";
			this.showPicker = true;
		},
		onEndtimePicker() {
			this.curTimeType = "end";
			this.showPicker = true;
		},
		formatter(type, val) {
			if (type === "year") {
				return `${val}年`;
			} else if (type === "month") {
				return `${val}月`;
			} else if (type === "day") {
				return `${val}日`;
			}
			return val;
		},
		changeTime(star) {
			let year = star.getFullYear();
			let month = star.getMonth() + 1;
			let day = star.getDate();
			if (month >= 1 && month <= 9) {
				month = `0${month}`;
			}
			if (day >= 1 && day <= 9) {
				day = `0${day}`;
			}
			const timeShow = `${year}-${month}-${day}`;
			if (this.curTimeType === "beg") {
				this.startTime = timeShow;
			} else {
				this.endTime = timeShow;
			}

			this.showPicker = false;
		},
		onConfirm() {
			const params = {
				tag: this.tagList.filter((item) => (item.select ? true : false)).map((item) => item.name),
				dept: this.deptList.filter((item) => (item.select ? true : false)).map((item) => item.name),
				projectType: this.projectList.filter((item) => (item.select ? true : false)).map((item) => item.name),
				subject: this.subjectList.filter((item) => (item.select ? true : false)).map((item) => item.name),
				startTime: this.startTime,
				endTime: this.endTime,
			};
			this.$emit("confirm", params);
		},
		onRest() {
			this.tagList.forEach((item) => (item.select = false));
			this.deptList.forEach((item) => (item.select = false));
			this.projectList.forEach((item) => (item.select = false));
			this.subjectList.forEach((item) => (item.select = false));

			(this.startTime = ""), (this.endTime = "");
		},
		async getPolicyTag() {
			let [err, res] = await to(getPolicyTag());
			if (err) {
				return;
			}
			res.tag.forEach((item) => {
				this.tagList.push({ name: item, select: false });
			});
		},
	},
};
</script>

<style scoped>
.filter-mask {
	width: 80px;
	background: #333;
	opacity: 0.3;
}

.filter-content {
	font-size: 16px;
	flex: 1;
	background: #fff;
	overflow: auto;
	position: relative;
	display: flex;
	flex-direction: column;
}

.filter-content__con {
	flex: 1;
	margin-bottom: 50px;
	overflow-y: auto;
}

.filter-title {
	padding: 17px 16px;
	color: #333333;
	border: 1px solid #f5f5f5;
}

.filter-cardbox {
	margin-top: 16px;
	padding: 0 16px;
	font-weight: 700;
}

.filter-cardbox__inner {
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.filter-cardbox__inner span {
	background-color: rgba(47, 63, 84, 0.1);
	border-radius: 6px;
	font-size: 14px;
	color: #333333;
	padding: 10px 10px;
	margin: 0 8px 10px 0;
	border: solid 1px #fff;
	box-sizing: border-box;
	font-weight: 500;
}

.filter-cardbox__selected {
	color: #fa6725 !important;
	border: solid 1px #fa6725 !important;
	background-color: #fff !important;
}

.filter-cardbox__time {
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.filter-cardbox__time >>> .van-cell {
	width: 100px;
	padding: 6px 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(47, 63, 84, 0.1);
	border-radius: 6px;
	font-weight: 500;
	font-size: 14px;
}

.filter-content__btn-box {
	/* font-size: 16px; */
	width: 100%;
	height: 50px;
	line-height: 50px;
	color: #333333;
	background: #fff;
	position: absolute;
	bottom: 0;
	display: flex;
	border-top: 1px solid #f5f5f5;
	box-sizing: border-box;
}

.filter-content__btn-reset {
	flex: 1;
	text-align: center;
}

.filter-content__btn-confirm {
	color: #fff;
	flex: 1;
	text-align: center;
	background-color: #fa6725;
}
</style>
