<template>
    <div class="empty-box">
        <img class="empty-box__img" src="@/assets/imgs/nodata.png"/>
        <div class="empty-box__tip">{{tips}}</div>
    </div>
</template>

<script>
export default {
    props: {
        tips: {
            type: String,
            default: '还没有相关数据'
        }
    }
}
</script>

<style scoped>
.empty-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.empty-box__img {
    margin-top: 100px;
    height: 120px;
}

.empty-box__tip {
    color: #999CA0;
    font-size: 14px;
}
</style>