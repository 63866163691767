<template>
  <div>
    <div class="policy-wrapper">
      <div class="policy-title" v-html="brighten(info.title)">
      </div>
      <label-top v-if="info.isTop"></label-top>

      <div class="policy-box">
        <template v-if="info.tag != null">
          <span
            v-for="(item, idx) in info.tag.split(',')"
            :key="idx"
            class="policy-box__tag"
            >{{ item }}</span
          >
        </template>
          <span
            v-if="info.digest != null"
            class="policy-box__digest"
            v-html="brighten(info.digest)"
          ></span>
      </div>

      <div v-if="info.refNumber" class="policy-bottom">
        <span>{{ info.refNumber }}</span>
        <span>{{ info.effectiveDate }} 生效</span>
      </div>
      <van-divider />
    </div>
  </div>
</template>

<script>
import { Divider } from 'vant';
import LabelTop from "@/views/policy/LabelTop";
export default {
  components: {
    LabelTop,
    [Divider.name]: Divider
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    kw: {
      type: String,
      default: "",
    },
  },
  methods: {
    brighten(val) {
      const reg = new RegExp(this.kw, "i");
      return val.replace(reg, `<span style="color:#fa6725">${this.kw}</span>`);
    },
  },
};
</script>

<style scoped>
.policy-wrapper {
  background-color: #fff;
}

.policy-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.policy-box {
  display: flex;
  margin: 12px 0;
  font-size: 12px;
}

.policy-box__tag {
  margin-right: 18px;
  color: #658CF1;
}

.policy-box__digest {
  margin-left:auto;
  color: #B3B3B3;
}


.policy-bottom {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  color: #333;
}
</style>
