<template>
    <div class="empty-layout">
        <img class="empty-layout__img" src="@/assets/imgs/neterr.png" />
        <div class="empty-layout__title">内容加载失败，请检查网络</div>
        <div class="empty-layout__btn" @click="$emit('reload')">重新加载</div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.empty-layout {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    position: relative;
}

.empty-layout__img {
    margin-top: 100px;
    width: 120px;
}

.empty-layout__title {
    margin-top: 25px;
    margin-bottom: 15px;
    color: #999CA0;
}

.empty-layout__btn {
    color: #343941;
    padding: 5px 16px;
    border: 2px solid rgba(193,194,197,1);
    border-radius: 8px;
}
</style>