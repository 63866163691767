<template>
  <page-layout
    style="background-color: #ffffff;; padding: 0px 20px;"
  >
    <div class="search-box">
      <div style="display: flex; align-items: center">
        <img class="search-box__search" src="@/assets/imgs/policy/search.png" />
        <input
          class="search-box__input"
          placeholder="请输入文件标题或文号"
          v-model="kw"
        />
      </div>
      <div @click="onFilterShow" class="search-box__filter">
        <div v-bind:class="[hasFilterData ? 'search-box__has-data' : '']">
          筛选
        </div>
        <img
          class="search-box__select"
          :src="hasFilterData ? filterIcon.filterSel : filterIcon.filter"
        />
      </div>
    </div>
    <img class="cal" src="@/assets/imgs/policy/cal.png" @click="$router.push({name: 'PolicyCalc' })"/>
    <div class="title-box">
      <div class="title-box__img"></div>
      <div class="title-box__text">最新政策</div>
    </div>
    <div ref="list" class="list">
      <van-list
        v-if="dataList.length > 0"
        v-model="loading"
        :error.sync="loadError"
        error-text="请求失败，点击重新加载"
        :finished-text="finishedText"
        :finished="finished"
        @load="getList"
      >
        <policy-item
          v-for="item in dataList"
          :key="item.id"
          @click.native="goto(item)"
          :info="item"
          :kw="kw"
        ></policy-item>
      </van-list>
      <empty-tip v-else tips="没有找到相关数据"></empty-tip>
    </div>

    <policy-filter
      v-bind:class="['filter', showFilter ? '' : 'hidden']"
      @confirm="onFilterConfirm"
      @close="showFilter = false"
    >
    </policy-filter>
  </page-layout>
</template>

<script>
import PageLayout from "@/layout/PageLayout";
import { List } from "vant";
import PolicyItem from "./PolicyItem";
import PolicyFilter from "./PolicyFilter";
import { getPolicyList } from "@/api/policy";
import debounce from "lodash/debounce";
import to from "@/utils/to";
import EmptyTip from "@/components/EmptyTip";

export default {
  components: {
    PageLayout,
    [List.name]: List,
    PolicyItem,
    PolicyFilter,
    EmptyTip,
  },
  data() {
    return {
      loading: false,
      loadError: false,
      finished: false,
      pageNum: 1,
      kw: "",
      dataList: [],
      finishedText: "到底了哦~",

      showFilter: false,
      filterIcon: {
        filter: require("@/assets/imgs/policy/select.png"),
        filterSel: require("@/assets/imgs/policy/select_sel.png"),
      },

      filterParams: {},
      scrollTop: 0,
    };
  },
  computed: {
    hasFilterData() {
      const { dept, projectType, subject } = this.filterParams;
      if (dept != null && dept.length > 0) return true;
      if (projectType != null && projectType.length > 0) return true;
      if (subject != null && subject.length > 0) return true;

      return false;
    },
  },
  watch: {
    kw() {
      this.kwSearchDebounce();
    },
  },
  mounted() {
    this.search();
    this.kwSearchDebounce = debounce(this.search, 500);
  },
  activated() {
    this.$refs.list.scrollTop = this.scrollTop;
  },
  methods: {
    onFilterConfirm(params) {
      this.showFilter = false;

      this.pageNum = 1;
      this.filterParams = params;
      this.getList();
    },
    search() {
      this.pageNum = 1;
      this.getList();
    },
    async getList() {
      this.loading = true;
      if (this.pageNum === 1) {
        this.finished = false;
        this.dataList = [];
      }

      this.finishedText = "到底了哦~";

      let params = {
        kw: this.kw,
        pageNum: this.pageNum,
      };
      Object.assign(params, this.filterParams);

      let [err, res] = await to(getPolicyList(params));
      this.loading = false;
      if (err) {
        this.loadError = true;
        return;
      }

      if (res.rows.length === 0) {
        this.finished = true;
        return;
      }

      this.dataList = this.dataList.concat(res.rows);
      console.log("dataList:", this.dataList.length);

      this.loading = false;
      this.pageNum = res.pageNum + 1;
    },
    goto({ id }) {
      // console.log('list scroll top', this.$refs.list.scrollTop)
      this.scrollTop = this.$refs.list.scrollTop;
      this.$router.push({
        name: "policyPage",
        query: {
          id,
        },
      });
    },
    onFilterShow() {
      this.showFilter = true;
    },
  },
};
</script>

<style scoped>
.search-box {
  width: 100%;
  font-size: 12px;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  z-index: 8;
}

.search-box__input {
  width: 260px;
  padding: 10px 6px 10px 26px;
  font-size: 12px;
  box-shadow: 0px 4px 14px 1px rgba(204, 204, 204, 0.3);
  border-radius: 3px;
  border: solid 1px #999999;
  margin-left: -24px;
}

.search-box__filter {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 12px;
}

.search-box__search {
  width: 18px;
  height: 18px;
  z-index: 2;
  margin-left: 10px;
}

.search-box__select {
  margin-left: 2px;
  width: 18px;
  height: 18px;
}

.search-box__has-data {
  color: #fa6725;
}

.list {
  margin-top: 10px;
  height: calc(100vh - 250px);
  overflow-y: scroll;
}

.filter {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
}

.hidden {
  display: none;
}

.cal {
  display: fixed;
  width: 100%;
  height: 120px;
  border-radius: 4px;
}

.title-box {
  display: flex;
  height: 28px;
  align-items: center;
}

.title-box__img {
  width: 4px;
  height: 16px;
  background: #393645;
  margin-right: 10px;
}

.title-box__text {
  height: 28px;
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  color: #333333;
}
</style>