import axios from 'axios'
import QS from 'qs'
import toLogin from '@/utils/login'
import store from '@/store'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.default.timeout = 10000

axios.interceptors.request.use(
    config => {
      const token = store.getters.token
      // const token= "51a4b55ee2cf4bdb8ffc63bc4b84c395"
      if (token) {
          config.headers['xt'] = token;
      }

      return config;
    },
    error => {
        console.log(error || '系统繁忙')
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return Promise.resolve(response)
    },
    error => {
        if (error.response != undefined 
            && error.response.data.code === 40240001) {
            store.commit('clearUser')
            toLogin(location.href)
        }

        return Promise.reject(error)
    }
)


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
      paramsSerializer: params => {
        return QS.stringify(params, {indices: false})
      }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err)
    })
  });
}

function render(template, context) {
    return template.replace(/\{\{(.*?)\}\}/g, (match, key) => context[key]);
}


/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function postJson(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
    url = render(url, params)
    axios.post(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err)
      })
  });
}

export function postForm(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    url = render(url, params)
    axios.post(url, QS.stringify(params))
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err)
      })
  });
}

export function postFormData(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * delete方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function del(url, params) {
  return new Promise((resolve, reject) => {
    url = render(url, params)
    axios.delete(url, {
      params: params
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err)
    })
  });
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
    url = render(url, params)
    axios.put(url, params).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err)
    })
  });
}

/**
 * patch方法，对应patch请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function patch(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
    url = render(url, params)
    axios.patch(url, params).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err)
    })
  });
}

