import { get } from './http.js'

/**
 * 获取政策列表
 * @param {*} params
 */
export const getPolicyList = params => get('/policy/v1.0/docs', params)

/**
 * 获取政策详情
 * @param {*} id
 * @param {*} params
 */
export const getPolicyDetail = id => get(`/policy/v1.0/docs/${id}`)


/**
 * 获取政策tag
 */
 export const getPolicyTag = () => get('/policy/v1.0/docs/tags')

 /**
  * 政策对应的项目
  * @returns 
  */
 export const getPolicyProject = params => get('/policy/v1.0/project', params)