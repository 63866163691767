<template>
    <div class="triangle-topright">
        <span class="word">置顶</span>
    </div>
</template>

<script>
    export default {
        name: "LabelTop"
    }
</script>

<style scoped>
.triangle-topright {
    margin: -66px -18px 0 0;
    float: right;
    font-size: xx-small;
    border-top: 30px solid #f9d441;
    border-left: 40px solid transparent;
    position: relative;
}

.word {
    font-size: 10px;
    text-align: center;
    position: absolute;
    display: inline-block;
    width: 38px;
    right: 0;
    top: -28px;
    color: #333333;
    transform-origin: bottom center;
    transform: rotate(40deg);
    font-size: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>